import React from 'react';

import styles from './hero-frame.module.scss';
import BackgroundLine from './svg/background-line.inline.svg';

export const HeroFrame = (props) => {
  const { wrapperLabel, lineLabel } = props;
  return (
    <div className={`${styles.backgroundWrapper} ${wrapperLabel || ''}`}>
      <BackgroundLine className={`${styles.backgroundLine} ${lineLabel || ''}`} />
    </div>
  );
};
