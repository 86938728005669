import { SEO } from 'components/blocks/seo';
import Mdx from 'components/containers/mdx';
import styles from 'components/pages/our-beliefs/our-beliefs.module.scss';
import { CTA } from 'components/shared/cta';
import { PageInfo } from 'components/shared/page-info';
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const ProfessionalServices = ({ data }) => {
  const { body: content, headings } = data.file.children[0];

  return (
    <DefaultLayout>
      <PageInfo
        title={'Professional Services'}
        className={styles.pageInfo}
        description={'Helping teams to get performance testing - quickly - on the right track.'}
        withBgPattern
      />

      <Mdx className={styles.contentWrapper} content={content} mode={'withToc'} headings={headings} />
      <CTA
        title={'Do you want to know more?'}
        description={'Schedule a meeting with our team and tell us more about your project.'}
        buttonText={'Talk to us'}
        buttonRef={'https://go2.grafana.com/k6-contact.html'}
        themeLight
      />
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    file(name: { eq: "professional-services" }, relativeDirectory: { eq: "markdown" }) {
      children {
        ... on Mdx {
          body
          headings {
            depth
            value
          }
        }
      }
    }
  }
`;

export default ProfessionalServices;

export const Head = () => <SEO {...SEO_DATA['professional-services']} />;
