import classNames from 'classnames/bind';
import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { HeroFrame } from 'components/shared/hero-frame';
import React from 'react';

import styles from './page-info.module.scss';
import Bg from './svg/bg.inline.svg';

const cx = classNames.bind(styles);

export const PageInfo = ({
  title,
  description,
  buttonText,
  buttonURL,
  buttonOnClick,
  buttonRef,
  className,
  withBgPattern,
  bgPattern: CustomBg,
}) => (
  <section className={cx('wrapper', className)}>
    {withBgPattern && CustomBg ? (
      <CustomBg className={styles.bg} />
    ) : (
      <>
        <Bg className={cx('bg', 'left')} />
        <Bg className={cx('bg', 'right')} />
      </>
    )}

    <div className={'container'}>
      <Heading className={styles.title} tag={'h1'}>
        {title}
      </Heading>
      {description && <p className={styles.description}>{description}</p>}
      {buttonText && buttonURL ? (
        <Button className={styles.button} tag={'link'} to={buttonURL} cursor>
          {buttonText}
        </Button>
      ) : (
        buttonText &&
        buttonRef && (
          <Button className={styles.button} tag={'a'} href={buttonRef} cursor>
            {buttonText}
          </Button>
        )
      )}
      {buttonText && buttonOnClick && (
        <Button className={styles.button} onClick={buttonOnClick} cursor>
          {buttonText}
        </Button>
      )}
    </div>
    <HeroFrame />
  </section>
);
