import classNames from 'classnames/bind';
import { Button } from 'components/shared/button';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import stylesCentered from './cta-centered.module.scss';
import styles from './cta.module.scss';
import ButtonBgSmall from './svg/button-bg-small.inline.svg';
import ButtonBg from './svg/button-bg.inline.svg';
import shapesLeftMobile from './svg/left-mobile.svg';
import shapesLeft from './svg/left.svg';
import shapesRight from './svg/right.svg';

const centered = classNames.bind(stylesCentered);

const Default = ({
  size,
  title,
  description,
  buttonText,
  buttonURL,
  buttonRef,
  buttonTarget,
  openDrift,
  themeLight,
}) => (
  <section className={`${styles.wrapper} ${styles[size]}`}>
    <div className={'container'}>
      <div className={'row'}>
        <div className={'col-xl-10 offset-xl-1'}>
          <div className={themeLight && styles.wrapperLight}>
            <div className={styles.inner}>
              <div className={styles.content}>
                <p className={styles.title}>{title}</p>
                {description ? <p className={styles.description}>{description}</p> : null}
              </div>

              <div className={styles.buttonWrapper}>
                {themeLight && size === 'md' && <ButtonBg className={styles.buttonBg} />}
                {themeLight && size === 'sm' && (
                  <ButtonBgSmall className={classNames(styles.buttonBg, styles.buttonBgSmall)} />
                )}
                {openDrift ? (
                  <Button
                    className={styles.ctaButton}
                    size={'lg'}
                    theme={!themeLight && 'gradient-primary'}
                    onClick={() => {
                      if (window.drift && typeof window.drift.api !== 'undefined') {
                        window.drift.api.sidebar.toggle();
                      } else if (window.HubSpotConversations && window.HubSpotConversations.widget) {
                        window.HubSpotConversations.widget.open();
                      } else {
                        navigate('/contact#contact-form');
                      }
                    }}
                    cursor
                  >
                    {buttonText}
                  </Button>
                ) : (
                  <Button
                    className={styles.ctaButton}
                    tag={buttonRef ? 'a' : 'link'}
                    href={buttonRef || buttonURL}
                    target={buttonTarget}
                    size={'lg'}
                    theme={!themeLight && 'gradient-primary'}
                    cursor
                  >
                    {buttonText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Centered = ({
  size,
  title = 'Grafana Cloud k6',
  description = 'Not sure where to start? Run 500 VU hours each month for free',
  children,
}) => (
  <section className={centered('wrapper', size)}>
    <div className={'container'}>
      <div className={'row'}>
        <div className={'col-xl-10 offset-xl-1'}>
          <div className={centered('wrapperLight')}>
            <div className={centered('inner')}>
              <div className={centered('content')}>
                <p className={centered('title')}>{title}</p>
                {description ? <p className={centered('description')}>{description}</p> : null}
              </div>
              <div className={centered('buttonWrapper')}>
                {children || (
                  <>
                    <Button
                      tag={'link'}
                      size={'md'}
                      href={'https://grafana.com/auth/sign-up/create-user?src=k6io'}
                      cursor
                    >
                      create free account
                    </Button>
                    <Button
                      tag={'a'}
                      size={'md'}
                      theme={'additional'}
                      href={'https://go2.grafana.com/k6-contact.html'}
                      cursor
                    >
                      request demo
                    </Button>
                  </>
                )}
              </div>
              <img className={centered('shapesLeft')} src={shapesLeft} alt="" aria-hidden />
              <img className={centered('shapesLeftMobile')} src={shapesLeftMobile} alt="" aria-hidden />
              <img className={centered('shapesRight')} src={shapesRight} alt="" aria-hidden />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const MODES = {
  default: Default,
  centered: Centered,
};

export const CTA = ({ mode, size, ...rest }) => {
  const ChosenCta = MODES[mode];
  return <ChosenCta size={size} {...rest} />;
};

CTA.propTypes = {
  mode: PropTypes.oneOf(Object.keys(MODES)),
  size: PropTypes.oneOf(['md', 'sm']),
};

CTA.defaultProps = {
  mode: 'default',
  size: 'md',
};
